import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Header from "../Header";
import {
  MapContainer,
  TileLayer,
  Polyline,
  LayersControl,
  Popup,
  Tooltip as Tooltips,
  Marker,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import calenderIcon from "../assets/calendar.png";
import truckIcon from "../assets/truck.png";
import listIcon from "../assets/listAlt.png";
import locationIcon from "../assets/location.png";
import EyeIcon from "../assets/redEye.png";
import TruckGreen from "../assets/TruckGreen.png";
import checkcircle from "../assets/checkcircle.png";

import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NearMeIcon from "@mui/icons-material/NearMe";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, Space, Spin } from "antd";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SuvegaRoundLogo from "../assets/SuvegaRoundLogo.png";
import Button from "@mui/material/Button";
import "./OrderTracking.css";
const drawerWidth = "25%";
const OrderTracking = (props) => {
  let getCenter = localStorage.getItem("center");
  let getLatLng = JSON.parse(getCenter);
  const [center, setCenter] = useState(getLatLng);
  const [map, setMap] = useState(null);
  const [position, setPosition] = useState({
    lat: Number(0),
    lng: Number(0),
  });
  const navigate = useNavigate();
  const [responseStatus, setResponseStatus] = useState(false);
  const [responseMessage, setResponseMessage] = useState(false);
  const [trackingData, setTrackingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [direction, setDirection] = useState();
  useEffect(() => {
    setLoading(true);
    let path = location.pathname.split("/");
    const desiredPath = path.length > 2 ? path[2] : null;
    // let newPath = localStorage.setItem('path',desiredPath)
    let oldPath = localStorage.getItem("path");
    const jwtToken = Cookies.get("jwtToken");
    if (jwtToken) {
      let headers = { Authorization: `Bearer ${jwtToken}` };
      const verifyOtpAndFetchData = async () => {
        try {
          const orderTrackingRes = await axios.post(
            `${process.env.REACT_APP_TRACKING_API}/vehicletracking/orderDetails`,
            {
              linkSuffix: desiredPath,
              // "otp": otp
            },
            { headers }
          );
          debugger;
          if (orderTrackingRes.data.status === true) {
            setLoading(false);
            setTrackingData(orderTrackingRes?.data?.data);
            setCenter([
              Number(orderTrackingRes?.data?.data?.vehicle?.latitude),
              Number(orderTrackingRes?.data?.data?.vehicle?.longitude),
            ]);
            setPosition({
              lat: Number(orderTrackingRes?.data?.data?.vehicle?.latitude),
              lng: Number(orderTrackingRes?.data?.data?.vehicle?.longitude),
            });
            setDirection(orderTrackingRes?.data?.data?.vehicle?.direction);
            // setOrderTrackingData(orderTrackingRes.data);
            // navigate(`/orderTracking/${pathname}`);
            // setIsVerified(true);
            // setMsg(orderTrackingRes.message);
            // setAlert({
            //   open: true,
            //   severity: "success",
            //   message: orderTrackingRes.message,
            // });
          } else {
            debugger;
            setLoading(false);
            setResponseMessage(orderTrackingRes?.data?.message === "No data found"? true: false);
            setResponseStatus(
              orderTrackingRes?.data?.status === false ? true : false
            );
            setTimeout(() => {
              Cookies.remove("jwtToken");
              // localStorage.removeItem('path')
            }, 6000);
          }
        } catch (error) {
          console.log(error);
        }
      };

      verifyOtpAndFetchData();
    } else {
      // Token doesn't exist, handle as needed
      navigate(`/${oldPath}`);
    }
  }, []);
  const handleClose = () => {
    let oldPath = localStorage.getItem("path");

    setResponseStatus(false);
    navigate(`/${oldPath}`);
  };
  return (
    <div>
      <Header />
      {/* <div style={{ display: "flex" }}> */}
      {responseStatus && !responseMessage ? (
        <div>
          <Modal
            open={responseStatus}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 532,
                height: 315,
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                // bgcolor: "background.paper",
                border: "1px solid #707070",
                borderRadius: "10px",
                opacity: 1,
                // boxShadow: 24,
                // p: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={SuvegaRoundLogo}
                alt="SuvegaRoundLogo"
                style={{ width: "149px", height: "149px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={checkcircle}
                  alt="checkcircle"
                  style={{
                    width: "35px",
                    height: "33px",
                    paddingRight: "10px",
                  }}
                />
                <Typography>Your Trip is Successfully Completed!</Typography>
              </div>
              <Button
                onClick={handleClose}
                style={{
                  width: "77px",
                  height: "31px",
                  background: "#397093",
                  fontSize: "14px",
                  color: "white",
                  fontWeight: 600,
                  marginTop: 10,
                }}
              >
                Close
              </Button>
            </Box>
          </Modal>
        </div>
      ) : responseStatus && responseMessage ? (
        <div>
        <Modal
          open={responseStatus}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 532,
              height: 315,
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              // bgcolor: "background.paper",
              border: "1px solid #707070",
              borderRadius: "10px",
              opacity: 1,
              // boxShadow: 24,
              // p: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={SuvegaRoundLogo}
              alt="SuvegaRoundLogo"
              style={{ width: "149px", height: "149px" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={checkcircle}
                alt="checkcircle"
                style={{
                  width: "35px",
                  height: "33px",
                  paddingRight: "10px",
                }}
              />
              <Typography>Invalid Link</Typography>
            </div>
            <Button
              onClick={handleClose}
              style={{
                width: "77px",
                height: "31px",
                background: "#397093",
                fontSize: "14px",
                color: "white",
                fontWeight: 600,
                marginTop: 10,
              }}
            >
              Close
            </Button>
          </Box>
        </Modal>
      </div>
      ) : null }
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            background: "transparent",
          }}
        >
          <Space direction="vertical">
            <Space>
              <Spin tip="Loading" size="large">
                <div style={{ padding: "50px" }} />
              </Spin>
            </Space>
          </Space>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: window.innerWidth <= 768 ? "column" : "row", // Change layout based on screen width
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "70%" }}>
          <MapContainer
            center={center}
            zoom={12}
            dragging={true}
            scrollWheelZoom={true}
            style={{ width: "100%", height: "calc(100vh - 60px)" }}
            whenCreated={(map) => {
              setMap(map);
            }}
            className={"icon"}
          >
            <LayersControl>
              <LayersControl.BaseLayer checked={false} name="Satellite">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                  subdomains={["mt0", "mt1", "mt2", "mt3"]}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer checked name="Terrain">
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>
              {/* {trackData.length > 0 ? (
                    <Polyline
                      positions={trackData.map((loc) => {
                        return [
                          Number(loc["position.latitude"]),
                          Number(loc["position.longitude"]),
                        ];
                      })}
                      color={"#00a1e1"}
                    />
                  ) : (
                    ""
                  )} */}
              {/* {liveTrackData.length > 0 ? (
                    <Polyline
                      positions={liveTrackData.map((loc) => {
                        return [
                          Number(loc["position.latitude"]),
                          Number(loc["position.longitude"]),
                        ];
                      })}
                      color={"green"}
                    />
                  ) : (
                    ""
                  )} */}
              {/* <ReactLeafletDriftMarker
                position={position}
                duration={2000}
                dragging={true}
                keepAtCenter={false}
                icon={L.divIcon({
                  iconSize: new L.Point(30, 30),
                  iconAnchor: [15, 20],
                  html: renderToStaticMarkup(
                    <img
                      src={TruckGreen}
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "transparent",
                        transform: `rotate(${direction}deg)`,
                      }}
                    />
                  ),
                })}
              >
              </ReactLeafletDriftMarker> */}
              <Marker
                key={props.key}
                position={position}
                // icon={L.divIcon(SetDivIcon(`rotate(${direction || 0}deg)`))}
                icon={L.divIcon({
                  iconSize: new L.Point(30, 30),
                  iconAnchor: [15, 20],
                  html: renderToStaticMarkup(
                    <img
                      src={TruckGreen}
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "transparent",
                        transform: `rotate(${direction}deg)`,
                      }}
                    />
                  ),
                })}
                style={{ background: "transperent" }}
                keepAtCenter={true}
              >
                {/* <Tooltips>
                  <div style={{ padding: 10 }}>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {props.vehicleDetails.vehicle}
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      Latitude : {position[0]},Longitude : {position[1]}
                    </p>
                  </div>
                </Tooltips> */}
              </Marker>
            </LayersControl>
          </MapContainer>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          {/* {loading ? <CircularProgress /> : null} */}
        </div>
        <div
          style={{
            width: "30%",
            padding: 14,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography
                style={{
                  textAlign: "left",
                  fontSize: "24px",
                  fontWeight: "bold",
                  letterSpacing: "0px",
                  color: "#366E93",
                  opacity: 1,
                }}
              >
                Hello User,
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={calenderIcon}
                alt="img"
                style={{ width: "17px", height: "15px" }}
              />
              <Typography
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "bold",
                  letterSpacing: "0px",
                  color: "#366E93",
                  opacity: 1,
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              >
                EAT
              </Typography>
              <Typography
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "bold",
                  letterSpacing: "0px",
                  color: "#707070",
                  opacity: 1,
                }}
              >
                {trackingData?.vehicle?.timestamp}
              </Typography>
            </div>
          </div>
          <Typography
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "bold",
              letterSpacing: "0px",
              color: "#EC5C44",
              opacity: 1,
            }}
          >
            Track your Order
          </Typography>
          <Typography
            style={{
              textAlign: "left",
              fontSize: "14px",
              letterSpacing: "0px",
              color: "#707070",
              opacity: 1,
            }}
          >
            Your order is on the way to the destination.
          </Typography>
          <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
            <img
              src={truckIcon}
              alt="img"
              style={{ width: "17px", height: "15px", paddingRight: "5px" }}
            />
            <Typography
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: 600,
                letterSpacing: "0px",
                color: "#366E93",
                opacity: 1,
              }}
            >
              Vehicle Number
            </Typography>
          </div>
          <Typography
            style={{
              textAlign: "left",
              fontSize: "16px",
              letterSpacing: "0px",
              color: "#707070",
              opacity: 1,
              paddingLeft: "30px",
            }}
          >
            {trackingData?.vehicle?.regiNo}
          </Typography>
          <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
            <img
              src={listIcon}
              alt="img"
              style={{ width: "17px", height: "15px", paddingRight: "5px" }}
            />
            <Typography
              style={{
                textAlign: "left",
                fontSize: "16px",
                letterSpacing: "0px",
                color: "#366E93",
                opacity: 1,
              }}
            >
              Driver Details
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <PersonIcon
              style={{
                width: "14px",
                height: "14px",
                paddingRight: "7px",
                color: "#707070",
              }}
            />
            <Typography
              style={{
                textAlign: "left",
                fontSize: "16px",
                letterSpacing: "0px",
                color: "#707070",
                opacity: 1,
              }}
            >
              {trackingData?.driver?.driverName}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <LocalPhoneIcon
              style={{
                width: "14px",
                height: "14px",
                paddingRight: "7px",
                color: "#707070",
              }}
            />
            <Typography
              style={{
                textAlign: "left",
                fontSize: "16px",
                letterSpacing: "0px",
                color: "#707070",
                opacity: 1,
              }}
            >
              {trackingData?.driver?.driverMobile}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
            <img
              src={locationIcon}
              alt="img"
              style={{ width: "14px", height: "20px", paddingRight: "5px" }}
            />
            <Typography
              style={{
                textAlign: "left",
                fontSize: "16px",
                letterSpacing: "0px",
                color: "#366E93",
                opacity: 1,
              }}
            >
              Current Location
            </Typography>
          </div>
          <Typography
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "600px",
              letterSpacing: "0px",
              color: "#707070",
              opacity: 1,
              paddingLeft: "30px",
            }}
          >
            {trackingData?.vehicle?.address}
          </Typography>
          <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
            <NearMeIcon
              style={{ width: "20px", height: "20px", color: "#397397" }}
            />
            <Typography
              style={{
                textAlign: "left",
                fontSize: "16px",
                letterSpacing: "0px",
                color: "#366E93",
                opacity: 1,
              }}
            >
              Destination
            </Typography>
          </div>
          <Typography
            style={{
              textAlign: "left",
              fontSize: "16px",
              letterSpacing: "0px",
              color: "#EC5C44",
              opacity: 1,
              paddingLeft: "30px",
              paddingBottom: 10,
            }}
          >
            {trackingData?.waypoint?.wayPointName}
          </Typography>
          <Divider />
          <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
            <img
              src={EyeIcon}
              alt="img"
              style={{ width: "25px", height: "16px", paddingRight: "5px" }}
            />
            <Typography
              style={{
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "400",
                letterSpacing: "0px",
                color: "#EC5C44",
                opacity: 1,
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.suvegafleet&pcampaignid=web_share"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#EC5C44",
                  color: "#EC5C44",
                }}
              >
                Register
              </a>{" "}
              &{" "}
              <a
                href="https://app.suvegafleet.com/"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#EC5C44",
                  color: "#EC5C44",
                }}
              >
                Login
              </a>{" "}
              for More Details
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderTracking;
