
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OrderTracking from './OrderTracking/OrderTracking'
import OtpVerification from './Otp/OtpVerification';

const App = () =>{
  return (
    <Router>
      <Routes>
        <Route exact path="/*" element={<OtpVerification/>} />
        <Route path="/orderTracking/*" element={<OrderTracking />} />
      </Routes>
    </Router>
  );
}

export default App;

