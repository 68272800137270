import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import "./OtpVerification.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import OrderTracking from "../OrderTracking/OrderTracking";
import Paper from "@material-ui/core/Paper";
import Background from "../assets/Background.png";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import SuvegaRoundLogo from "../assets/SuvegaRoundLogo.png";
import checkcircle from "../assets/checkcircle.png";
const OtpVerification = () => {
  const [otp, setOTP] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [msg, setMsg] = useState();
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [trackingData, setTrackingData] = useState([]);
  const [responseStatus, setResponseStatus] = useState(false);

  const handleVerify = async () => {
    setIsLoading(true);
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_TRACKING_API}/vehicletracking/verifyUserOtp`,
        {
          linkSuffix: localStorage.getItem("path"),
          otp: otp,
        }
      );
      if (res.data.status === true) {
        setIsLoading(false);
        const pathname = location.pathname
          .split("/")
          .filter((i) => i?.length > 0)[0];
        const jwtToken = res.data.token;
        Cookies.set("jwtToken", jwtToken);
        let headers = { Authorization: `Bearer ${jwtToken}` };
        let orderTrackingRes = await axios.post(
          `${process.env.REACT_APP_TRACKING_API}/vehicletracking/orderDetails`,
          {
            linkSuffix: localStorage.getItem("path"),
            // "otp":otp
          },
          { headers }
        );
        if (orderTrackingRes?.data && orderTrackingRes.data.status === true) {
          // if(orderTrackingRes?.data && orderTrackingRes?.data.data){
          setTrackingData(orderTrackingRes?.data?.data);
          // }
          localStorage.setItem(
            "center",
            JSON.stringify([
              Number(orderTrackingRes?.data?.data?.vehicle?.latitude),
              Number(orderTrackingRes?.data?.data?.vehicle?.longitude),
            ])
          );
          navigate(`/orderTracking/${pathname}`);
          setIsVerified(true);
          setMsg(res.data.message);
          setAlert({
            open: true,
            severity: "success",
          });
        }
      } else {
        setMsg(res.data.message);
        setIsVerified(true);
        setAlert({
          open: true,
          severity: "error",
          message: res.data.message,
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  async function fetchData() {
    try {
      let oldPath = localStorage.getItem("path");
      let path = location.pathname.split("/").filter((i) => i?.length > 2);
      let newPath = localStorage.setItem("path", path);
      let pathname = localStorage.getItem("path");
      let jwtToken = Cookies.get("jwtToken");
      if (jwtToken && oldPath === path[0]) {
        navigate(`/orderTracking/${pathname}`);
      } 
      else {
              navigate(`/${pathname}`);
      }

      const res = await axios.post(
        `${process.env.REACT_APP_TRACKING_API}/vehicletracking/verifyUser`,
        {
          linkSuffix: localStorage.getItem("path"),
        }
      );

      if (res?.data?.status === true) {
        setAlert({
          open: true,
          severity: "success",
          message: res?.data?.message,
        });
      } else {
        setAlert({
          open: true,
          severity: "error",
          message: res?.data?.message,
        });
        setResponseStatus(true);
      }
    } catch (error) {
      // Handle any errors that occur during the async operations
      console.error("An error occurred:", error);
    }
  }
  useEffect(() => {
    // debugger
    // Define an async function inside the useEffect


    // Call the async function defined above
    fetchData();
  }, []);

  const handleResend = async () => {
    setIsLoading(true);
    // You can add your resend API call here...
    try {
      // Make the API call to resend OTP
      const res = await axios.post(
        `${process.env.REACT_APP_TRACKING_API}/vehicletracking/resendUserOtp`,
        {
          linkSuffix: localStorage.getItem("path"),
        }
      );

      if (res.data.status === true) {
        setIsLoading(false);
        // OTP has been resent successfully
        setAlert({
          open: true,
          severity: "success",
          message: "OTP has been resent successfully.",
        });
      } else {
        // Failed to resend OTP
        setAlert({
          open: true,
          severity: "error",
          message: "Failed to resend OTP. Please try again.",
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const changeHandler = (e) => {
    setOTP(e.target.value);
    setAlert({
      open: false,
      severity: "",
      message: "",
    });
  };

  return (
    <Paper
      style={{
        backgroundImage: `url(${Background})`,
        width: window.innerWidth,
        height: window.innerHeight,
        backgroundSize: "contain",
      }}
    >
      {responseStatus && responseStatus ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 460,
            height: 280,
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "10px",
            opacity: 1,
            boxShadow: 24,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={SuvegaRoundLogo}
            alt="SuvegaRoundLogo"
            style={{ width: "120px", height: "120px" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 20,
            }}
          >
            <img
              src={checkcircle}
              alt="checkcircle"
              style={{
                width: "35px",
                height: "33px",
                paddingRight: "10px",
              }}
            />
            <Typography>{alert?.message}</Typography>
          </div>
        </Box>
      ) : (
        <div className="otp-container">
          <Card className="otp-card">
            <CardContent>
              <Typography variant="h5" align="center" gutterBottom>
                OTP Verification
              </Typography>
              <Typography variant="body2" align="center" color="textSecondary">
                Enter the OTP sent to your Mobile
              </Typography>
              <TextField
                label="Enter OTP"
                fullWidth
                margin="normal"
                value={otp}
                onChange={(e) => changeHandler(e)}
              />
              <Typography
                onClick={handleResend}
                style={{
                  color: "#4c96fd",
                  fontSize: "14px",
                  fontWeight: 500,
                  textAlign: "right",
                }}
              >
                Resend OTP
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleVerify}
              >
                {isLoading ? (
                  <CircularProgress size={24} style={{ color: "#ffffff" }} /> // Show loading icon
                ) : (
                  "Verify OTP" // Show button text
                )}
              </Button>
              {alert.open ? (
                <Alert severity={alert.severity} style={{ marginTop: "16px" }}>
                  {alert.message}
                </Alert>
              ) : null}
            </CardContent>
          </Card>
          {trackingData && trackingData.length > 0 ? (
            <OrderTracking response={trackingData} />
          ) : null}
        </div>
      )}
    </Paper>
  );
};

export default OtpVerification;
